<template>
  <div class="card p-2" style="min-height: 200px">
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr>
          <th>Tag Type</th>
          <th>Name</th>
          <th>Code</th>
          <th>Create Date</th>
          <th>Start Date</th>
          <th>End Date</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in lists" :key="i">
          <td class="text-capitalize">{{ item.tag_type }}</td>
          <td>
            <span class="fw-bold">{{ item.name }}</span>
          </td>
          <td>{{ item.code }}</td>
          <td>{{ item.create_date }}</td>
          <td>{{ item.start_date }}</td>
          <td>{{ item.end_date }}</td>
          <td>
            <button class="btn btn-primary btn-sm" style="cursor: pointer" @click="goToEdit(item)">
              <i class="fas fa-pencil"></i>
            </button>
          </td>
        </tr>

        </tbody>
      </table>
      <p v-if="!lists.length" class="text-center mt-5">No data</p>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  lists: Array,
})
const $emit = defineEmits(['onEdit'])
const goToEdit = (item) => {
  $emit("onEdit", item)
}
</script>
