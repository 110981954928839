<template>
  <div style="min-height: calc(100vh - 158px);" class="card">
    <div style="background: #DBE7F2" class="p-2">
      <TitleButton
          btnTitle="Add New"
          :showBtn="false"
          :showAddNew="true"
          title="Tag Register List"
          @onClickAddNewButton="openModal"
      />
    </div>

    <ListTable
        :lists="tagRegisterList"
        @onEdit="handleEdit"
    />
    <div class="mb-2"></div>

    <div class="px-2 position-absolute bottom-0">
      <Pagination
          :offset="offset"
          @onPageChange="onPageChange"
          ref="pagination"
      />
    </div>
    <Loader v-if="loading"/>
  </div>
  <div class="mt-2"></div>
  <TagRegisterCreateEditModal
      :modalTitle="modalTitle"
      ref="tagRegisterModalRef"
      :formData="formData"
      @onSubmit="handleSubmit"
      @onClose="clearModal"
  />
</template>

<script setup>
import TitleButton from '@/components/atom/TitleButton'
import ListTable from '@/components/molecule/scm/tag-register/ListTable'
import Loader from '@/components/atom/LoaderComponent'
import TagRegisterCreateEditModal from '@/components/molecule/scm/tag-register/TagRegisterModal'
import handleTagRegister from '@/services/modules/scm/tag-register'
import {computed, inject, onMounted, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import Pagination from '@/components/atom/Pagination'
import {useStore} from "vuex";

const offset = ref(20);
const {fetchTagRegister, storeTagRegister, updateTagRegister} = handleTagRegister();
const showError = inject('showError');
const showSuccess = inject('showSuccess');
let loading = ref(false)
const type = ref('add')
const modalTitle = ref('Add Tag Register')
let tagRegisterList = ref([])
const formData = ref({
  company_id: '',
  name: '',
  code: '',
  tag_type: '',
  short_description: '',
  full_description: '',
  create_date: '',
  start_date: '',
  end_date: '',
  status: 'active'
})
const tagRegisterModalRef = ref(null);
const openModal = () => {
  clearModal()
  modalTitle.value = 'Add Tag Register'
  type.value = 'add'
  tagRegisterModalRef.value?.toggleModal()
}

const $route = useRoute()
const $router = useRouter()
const $store = useStore();
const clearModal = () => {
  formData.value = {
    company_id: '',
    name: '',
    code: '',
    tag_type: '',
    short_description: '',
    full_description: '',
    create_date: '',
    start_date: '',
    end_date: '',
    status: 'active'
  }
}

const handleSubmit = () => {
  tagRegisterModalRef.value.loading = true
  if (type.value == 'add') {
    formData.value.company_id = companyId.value
    storeTagRegister(formData.value).then(res => {
      showSuccess(res.message)
      tagRegisterModalRef.value.loading = false
      tagRegisterModalRef.value.toggleModal()
      getTagRegisters()
    }).catch(err => {
      handleError(err)
    })
  }
  if (type.value == 'edit') {
    updateTagRegister(formData.value.id, formData.value).then(res => {
      showSuccess(res.message)
      tagRegisterModalRef.value.loading = false
      tagRegisterModalRef.value.toggleModal()
      getTagRegisters()
    }).catch(err => {
      handleError(err)
    })
  }

}

const handleEdit = (item) => {
  clearModal()
  modalTitle.value = 'Edit Tag Register'
  type.value = 'edit'
  tagRegisterModalRef.value?.toggleModal()

  formData.value = item;
}

function handleError(err) {
  tagRegisterModalRef.value.loading = false
  if (!err.response) {
    showError("Something went wrong")
    return
  }
  if (err.response?.data) {
    showError(err.response.data.message)
  }
}


//computed
const companyId = computed(() => $route.params.companyId);
const page = computed(() => $route.query.page);

//methods
const setPaginationData = (data) => {
  $store.commit('setPaginationData', data)
};
const resetPagination = () => {
  $store.commit('resetPagination')
};

const getTagRegisters = async () => {
  const query = getQuery()

  try {
    loading.value = true
    let res = await fetchTagRegister(query)
    if (!res.status) {
      showError(res.message)
    }
    if (res.status) {
      tagRegisterList.value = res.data.data
      if (res.data.data.length > 0) {
        setPagination(res.data)
      }
    }
  } catch (err) {
    if (!err.response) {
      showError('Something is wrong. Check your connectivity!!')
    }
    if (err.response) {
      showError(err.response.message)
    }
  } finally {
    loading.value = false
  }
}

const setPagination = (data) => {
  setPaginationData({
    records: data.total,
    perPage: data.per_page,
    currentPage: data.current_page,
  })
}

const onPageChange = async (page) => {
  let routeQuery = Object.assign({}, $route.query)
  routeQuery.page = page
  await $router.push({path: $route.path, query: routeQuery})
  await getTagRegisters()
}

const getQuery = () => {
  let query = '?company_id=' + companyId.value
  query += '&offset=' + offset.value
  if (page) query += '&page=' + page.value
  return query
}

// hooks
onMounted(async () => {
  await getTagRegisters();
})
</script>
