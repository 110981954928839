import Network from '@/services/network'
import {ref} from 'vue'

export default function handleTagRegister() {
    const network = new Network;
    const loading = ref(false)

    const fetchPrPoList = (query) => {
        return network.api('get', '/pr-po/list' + query);
    }
    const fetchTagRegisterList = (query) => {
        return network.api('get', '/tag-register-scm/list' + query);
    }
    const fetchTagRegister = (query) => {
        return network.api('get', '/tag-register-scm' + query);
    }
    const storeTagRegister = (data) => {
        return network.api('post', '/tag-register-scm', data);
    }

    const updateTagRegister = (id, data) => {
        return network.api('put', '/tag-register-scm/' + id, data);
    }

    return {
        fetchPrPoList,
        fetchTagRegisterList,
        fetchTagRegister,
        storeTagRegister,
        updateTagRegister,
        loading
    }
}
