<template>
  <div v-if="isOpen" class="modal fade show" aria-labelledby="addNewCardTitle"
       style="display: block; background: #1c1d1c33">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="row">
          <div class="col-12">
            <div class="d-flex justify-content-between px-1 my-1 align-item-center">
              <h1 class="mb-0">{{ modalTitle }}</h1>
              <button
                  @click="toggleModal"
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
              ></button>
            </div>
          </div>
        </div>
        <hr class="mt-0">

        <div class="pb-2">
          <!-- form -->
          <vForm @submit="submit" :validation-schema="schema">
            <div class="row gy-2 px-2">
              <div class="col-md-12">
                <label class="form-label" for="tag_type">Tag Type</label>
                <vField v-model="formData.tag_type" class="d-none" name="tag_type"/>
                <v-select
                    v-model="formData.tag_type"
                    label="name"
                    :options="tagTypes"
                    :reduce="name => name.id"
                />
                <ErrorMessage name="tag_type" class="invalid-feedback d-block qcont"/>
              </div>
              <div class="col-12">
                <label class="form-label" for="chartName">Name</label>
                <div class="input-group">
                  <vField
                      type="text"
                      name="name"
                      v-model="formData.name"
                      class="form-control add-credit-card-mask"
                      placeholder="Name"
                  />
                  <ErrorMessage name="name" class="invalid-feedback d-block qcont"/>
                </div>
              </div>
              <div class="col-12">
                <label class="form-label" for="code">Code</label>
                <div class="input-group">
                  <vField
                      type="text"
                      name="code"
                      v-model="formData.code"
                      class="form-control add-credit-card-mask"
                      placeholder="Code"
                  />
                  <ErrorMessage name="code" class="invalid-feedback d-block qcont"/>
                </div>
              </div>
              <div class="col-12">
                <label class="form-label" for="short_description">Short Description</label>
                <vField
                    as="textarea"
                    v-model="formData.short_description"
                    name="short_description"
                    id="short_description"
                    class="form-control add-credit-card-mask"
                    placeholder="Short"
                />
                <ErrorMessage name="short_description" class="invalid-feedback d-block qcont"/>
              </div>
              <div class="col-12">
                <label class="form-label" for="full_description">Full Description</label>
                <vField
                    as="textarea"
                    v-model="formData.full_description"
                    name="full_description"
                    id="full_description"
                    class="form-control add-credit-card-mask"
                    placeholder="Full"
                />
                <ErrorMessage name="full_description" class="invalid-feedback d-block qcont"/>
              </div>
              <div class="col-12 col-sm-12 col-md-12">
                <label class="align-middle">Create Date</label>
                <input type="date" class="form-control" v-model="formData.create_date">
              </div>
              <div class="col-12 col-sm-6 col-md-6">
                <label class="align-middle">Start Date</label>
                <input type="date" class="form-control" v-model="formData.start_date">
              </div>

              <div class="col-12 col-sm-6 col-md-6">
                <label class="align-middle">End Date</label>
                <input type="date" class="form-control" v-model="formData.end_date">
              </div>
              <div class="col-md-12">
                <label class="form-label">Status</label>
                <vField v-model="formData.status" class="d-none" name="status"/>
                <v-select
                    placeholder="Select Status"
                    v-model="formData.status"
                    label="name"
                    :options="allStatus"
                    :reduce="name => name.id"
                />
              </div>
              <ErrorMessage name="status" class="invalid-feedback d-block qcont"/>
            </div>

            <div class="col-12 text-center">
              <hr class="mt-2">
              <button :disabled="loading" type="submit"
                      class="btn btn-primary me-1 mt-1 waves-effect waves-float waves-light">
                <div v-if="loading" class="spinner-border spinner-border-sm text-light" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                Submit
              </button>
              <button @click.prevent="toggleModal" class="btn btn-outline-secondary mt-1 waves-effect">
                Cancel
              </button>
            </div>
          </vForm>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>

import {ref} from "vue";

const props = defineProps({
  formData: Object,
  type: Object,
  modalTitle: {
    type: String,
    default: 'Add Tag Register'
  }
})

const $emit = defineEmits(['onSubmit', 'onClose'])

let isOpen = ref(false)
const loading = ref(false)
const schema = ref({
  name: 'required|min:3|max:100',
  tag_type: 'required',
  status: 'required',
})

const allStatus = ref([
  {id: 'active', name: 'Active'},
  {id: 'inactive', name: 'Inactive'}
])

const tagTypes = ref([
  {id: 'lc', 'name': 'LC'},
  {id: 'department', 'name': 'Department'},
  {id: 'events', 'name': 'Events'},
  {id: 'program', 'name': 'Program'},
  {id: 'project', 'name': 'Project'},
  {id: 'other', 'name': 'Other'}
])

const toggleModal = () => {
  isOpen.value = !isOpen.value
  $emit('onClose')
}

const submit = () => {
  $emit("onSubmit")
}

defineExpose({toggleModal, loading})

</script>
